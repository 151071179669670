// eslint-disable-next-line object-curly-newline
import { ref, watch, inject, computed } from '@vue/composition-api'
import store from '@/store'
import { isNavGroupActive } from '@core/layouts/utils'
import Ws from '@adonisjs/websocket-client'

export default function useVerticalNavMenuGroup(item) {
  // ------------------------------------------------
  // isVerticalMenuCollapsed
  // ------------------------------------------------
  const isVerticalMenuCollapsed = computed(() => store.state.verticalMenu.isVerticalMenuCollapsed)

  watch(isVerticalMenuCollapsed, val => {
    /* eslint-disable no-use-before-define */
    // * Handles case if routing is done outside of vertical menu
    // i.e. From Customizer Collapse or Using Link
    if (!isMouseHovered.value) {
      if (val) isOpen.value = false
      else if (!val && isActive.value) isOpen.value = true
    }
    /* eslint-enable */
  })

  // ------------------------------------------------
  // isMouseHovered
  // ------------------------------------------------
  const isMouseHovered = inject('isMouseHovered')

  // Collapse menu when menu is collapsed and show on open
  watch(isMouseHovered, val => {
    if (isVerticalMenuCollapsed.value) {
      // * we have used `val && val && isActive.value` to only open active menu on mouseEnter and close all menu on mouseLeave
      // * If we don't use `isActive.value` with `val` it can open other groups which are not active as well
      // eslint-disable-next-line no-use-before-define
      isOpen.value = val && isActive.value
    }
  })

  // ------------------------------------------------
  // openGroups
  // ------------------------------------------------
  const openGroups = inject('openGroups')

  // Collapse other groups if one group is opened
  watch(openGroups, currentOpenGroups => {
    const clickedGroup = currentOpenGroups[currentOpenGroups.length - 1]

    // If current group is not clicked group or current group is not active => Proceed with closing it
    // eslint-disable-next-line no-use-before-define
    if (clickedGroup !== item.title && !isActive.value) {
      // If clicked group is not child of current group
      // eslint-disable-next-line no-use-before-define
      if (!doesHaveChild(clickedGroup)) isOpen.value = false
    }
  })

  // ------------------------------------------------
  // isOpen
  // ------------------------------------------------
  const isOpen = ref(false)
  watch(isOpen, val => {
    // if group is opened push it to the array
    if (val) openGroups.value.push(item.title)
  })

  const updateGroupOpen = val => {
    // eslint-disable-next-line no-use-before-define
    isOpen.value = val
  }

  // ------------------------------------------------
  // isActive
  // ------------------------------------------------
  const isActive = ref(false)
  watch(isActive, val => {
    /*
      If menu is collapsed and not hovered(optional) then don't open group
    */
    if (val) {
      if (!isVerticalMenuCollapsed.value) isOpen.value = val
    } else {
      isOpen.value = val
    }
  })

  const updateIsActive = () => {
    isActive.value = isNavGroupActive(item.children)
  }
  const Token = localStorage.getItem('token')
  const CountData = ref(0)
  if (item.title === 'confirm') {
    const BaseWsUrl = store.state.app.BaseWsUrl;
    const WsMain = store.state.app.WsMain;
    const WsChat = store.state.app.WsChatCount;
    if (!WsMain) {
      if (Token) {
        const ws = Ws(BaseWsUrl);

        ws.withJwtToken(Token).connect();

        ws.on('open', () => {
          console.log('Connection established');
          store.commit('app/SET_WSMAIN', ws);
          // เรียกใช้ subscribeToChat หลังจาก WsMain เชื่อมต่อสำเร็จ
          subscribeToChat(ws, CountData, item);
        });

        ws.on('error', (error) => {
          console.debug('Connection Error', error);
        });

        ws.on('close', (event) => {
          console.debug('Connection closed', event);
        });
      } else {
        console.debug('No token found');
      }
    } else if (!WsChat) {
      // เรียกใช้ subscribeToChat เมื่อ WsMain ถูกกำหนด
      subscribeToChat(WsMain, CountData, item);
    }
  }
  // console.debug(item)

  // ------------------------------------------------
  // Other Methods
  // ------------------------------------------------

  const doesHaveChild = title => item.children.some(child => child.title === title)

  return {
    isOpen,
    isActive,
    updateGroupOpen,
    openGroups,
    isMouseHovered,
    updateIsActive,
    CountData
  }
}
const subscribeToChat = (wsMain, CountData, item) => {
  const UserData = JSON.parse(localStorage.getItem("userData"));
  if (UserData && UserData.agent_id) {
    const WsChatD = wsMain.subscribe(`chatcount:${UserData.agent_id}`);
    store.commit('app/SET_WSNOTIFYCOUNTCHAT', WsChatD);

    WsChatD.on('VerifyAll', (message) => {
      CountData.value = message || 0
    });

    WsChatD.on('open', () => {
      console.log("Chat WebSocket established");
    });

    WsChatD.on('error', (error) => {
      console.log("Chat WebSocket Error", error);
    });
  } else {
    console.debug('User data not found or invalid');
  }
};
