import { ref } from '@vue/composition-api'
import { isNavLinkActive, navLinkProps } from '@core/layouts/utils'
import Ws from '@adonisjs/websocket-client'
import store from '@/store';


export default function useVerticalNavMenuLink(item) {

  const isActive = ref(false)
  const Token = localStorage.getItem('token')
  const linkProps = navLinkProps(item)
  const CountData = ref(0)
  const WsChatD = ref(null)
  if (item.count) {
    const BaseWsUrl = store.state.app.BaseWsUrl;
    const WsMain = store.state.app.WsMain;
    const WsChat = store.state.app.WsChatCount;

    // เชื่อมต่อ WebSocket หลัก
    if (!WsMain) {
      if (Token) {
        const ws = Ws(BaseWsUrl);

        ws.withJwtToken(Token).connect();

        ws.on('open', () => {
          console.log('Connection established');
          store.commit('app/SET_WSMAIN', ws);
          // เรียกใช้ subscribeToChat หลังจาก WsMain เชื่อมต่อสำเร็จ
          subscribeToChat(ws, CountData, item);
        });

        ws.on('error', (error) => {
          console.debug('Connection Error', error);
        });

        ws.on('close', (event) => {
          console.debug('Connection closed', event);
        });
      } else {
        console.debug('No token found');
      }
    } else if (!WsChat) {
      // เรียกใช้ subscribeToChat เมื่อ WsMain ถูกกำหนด
      subscribeToChat(WsMain, CountData, item);
    }
  }

  // const CountChatData = CountChat
  const updateIsActive = () => {
    isActive.value = isNavLinkActive(item)
  }

  return {
    isActive,
    linkProps,
    updateIsActive,
    CountData,
  }
}
const subscribeToChat = (wsMain, CountData, item) => {
  const UserData = JSON.parse(localStorage.getItem("userData"));
  if (UserData && UserData.agent_id) {
    const WsChatD = wsMain.subscribe(`chatcount:${UserData.agent_id}`);
    store.commit('app/SET_WSNOTIFYCOUNTCHAT', WsChatD);
    WsChatD.on(item.route, (message) => {
      CountData.value = message || 0; 
    });

    WsChatD.on('open', () => {
      console.log("Chat WebSocket established");
    });

    WsChatD.on('error', (error) => {
      console.log("Chat WebSocket Error", error);
    });
  } else {
    console.debug('User data not found or invalid');
  }
};
