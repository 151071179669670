export default [
  {
    title: 'สินค้า',
    icon: 'far fa-shopping-bag',
    children: [
      {
        title: 'สินค้า',
        route: 'shop-mall',
      },
      {
        title: 'รายการสั่งซื้อ',
        route: 'order-list',
      },

      {
        title: 'เช็คสถานะและแจ้งส่งของ',
        route: 'order-status',
      },
      {
        title: 'รายการส่งออกสินค้า',
        route: 'export-list',
      },
      {
        title: 'รหัสพัสดุ',
        route: 'tracking-code',
      },
      {
        title: 'แจ้งสินค้าที่ไม่ได้รับ',
        route: 'product-not-received',
      },
      {
        title: 'สินค้าไม่มีเจ้าของ',
        route: 'product-no-owner',
      },
    ],
  },
]
