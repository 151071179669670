export default [
  // {
  //   title: 'Administrator',
  //   icon: 'Administrator.svg',
  //   tagVariant: 'light-warning',
  //   children: [
  //     {
  //       title: 'แดชบอร์ด',
  //       route: 'admin-dashboard',
  //       resource: 'admin-order',
  //       icon: 'dashboard.svg',
  //     },
  //   // {
  //   //   title: 'ผู้ใช้งาน',
  //   //   route: 'admin-employee',
  //   //   resource: 'admin-add-product',
  //   // },
  //   // {
  //   //   title: 'สินค้า',
  //   //   route: 'admin-mall-list',
  //   //   resource: 'admin-add-product',
  //   // },
  //   // {
  //   //   title: 'รายการสั่งซื้อ',
  //   //   route: 'admin-order',
  //   //   resource: 'admin-order',
  //   // },
  //   // {
  //   //   title: 'สร้างใบชำระให้ลูกค้า',
  //   //   route: 'admin-payment-user',
  //   //   resource: 'admin-paymenttochina',
  //   // },
  //   // {
  //   //   title: 'ส่งของไปจีน',
  //   //   route: 'admin-paymenttochina',
  //   //   resource: 'admin-paymenttochina',
  //   // },
  //   // {
  //   //   title: 'ใบเสร็จ',
  //   //   route: 'admin-finance-receipt',
  //   //   resource: 'admin-paymenttochina',
  //   // },
  //   // {
  //   //   title: 'พิมพ์รายการสั่งซื้อ',
  //   //   route: 'admin-order-print',
  //   //   resource: 'admin-paymenttochina',
  //   // },
  //   // {
  //   //   title: 'ชำระเงินค่าขนส่งสินค้า',
  //   //   route: 'admin-payment',
  //   //   resource: 'admin-payment',
  //   // },
  //   // {
  //   //   title: 'รายการส่งออกสินค้า',
  //   //   route: 'admin-export',
  //   //   resource: 'admin-export',
  //   // },
  //   // // {
  //   // //   title: 'เช็คสถานะและแจ้งส่งของ',
  //   // //   route: 'admin-order',
  //   // //   resource: 'admin-order',
  //   // // },
  //   // {
  //   //   title: 'รหัสพัสดุ',
  //   //   route: 'admin-tracking',
  //   //   resource: 'admin-tracking',
  //   // },
  //   // {
  //   //   title: 'รวมรหัสพัสดุ',
  //   //   route: 'admin-combine-track',
  //   //   resource: 'admin-tracking',
  //   // },
  //   // {
  //   //   title: 'แจ้งสินค้าไม่ได้',
  //   //   route: 'admin-lost-package',
  //   //   resource: 'admin-productfailed',
  //   // },
  //   // {
  //   //   title: 'ล็อตสินค้า',
  //   //   route: 'admin-warehouse-lot',
  //   //   resource: 'admin-productfailed',
  //   // },
  //   // // {
  //   // //   title: 'lot10',
  //   // //   route: 'admin-warehouse-lot10',
  //   // //   resource: 'admin-productfailed',
  //   // // },
  //   // {
  //   //   title: 'รับสินค้าเข้าไทย',
  //   //   route: 'admin-warehouse-thai',
  //   //   resource: 'admin-productfailed',
  //   // },
  //   // {
  //   //   title: 'ระบบ QC',
  //   //   route: 'admin-warehouse-qc',
  //   //   resource: 'admin-productfailed',
  //   // },
  //   // {
  //   //   title: 'ข้อมูลโกดัง',
  //   //   route: 'admin-warehouse-section',
  //   //   resource: 'admin-productfailed',
  //   // },
  //   // {
  //   //   title: 'แต้ม',
  //   //   route: 'admin-point',
  //   //   resource: 'admin-productfailed',
  //   // },
  //   // {
  //   //   title: 'แลกแต้ม',
  //   //   route: 'admin-point-withdraw',
  //   //   resource: 'admin-productfailed',
  //   // },
  //   // {
  //   //   title: 'สินค้าไม่มีเจ้าของ',
  //   //   route: 'admin-goodsconfirm',
  //   //   resource: 'admin-productfailed',
  //   // },
  //   // {
  //   //   title: 'สต๊อคคงเหลือ',
  //   //   route: 'admin-mall-stock',
  //   //   resource: 'admin-productfailed',
  //   // },
  //   // // {
  //   // //   title: 'เช็คสถานะและแจ้งส่งของ',
  //   // //   route: 'admin-payment-create',
  //   // //   resource: 'admin-productfailed',
  //   // // },
  //   // {
  //   //   title: 'รันแท็ค',
  //   //   route: 'admin-runtrack',
  //   //   resource: 'admin-track',
  //   // },
//
  // ],
  // },
  {
    // title: 'แดชบอร์ด',
    title: 'dashboard',
    route: 'admin-dashboard',
    resource: 'admin-dashboard',
    icon: 'dashboard.svg',
  },
  {
    // title: 'รันแท็ค',
    title: 'runTrack',
    route: 'admin-RunTrack',
    resource: 'admin-RunTrack',
    icon: 'runtrack.png',
  },
  {
    // title: 'แชท',
    title: 'chat',
    route: 'admin-chat',
    resource: 'admin-chat',
    icon: 'chat.svg',
    count: true,
  },
  {
    // title: 'ผู้ใช้งาน',
    title: 'user',
    route: 'admin-employee',
    resource: 'admin-user',
    icon: 'user.svg',
  },
  {
    // title: 'สินค้า',
    title: 'product',
    route: 'admin-mall-list',
    resource: 'admin-mall-list',
    icon: 'product.svg',
  },
  {
    // title: 'รายการสั่งซื้อ',
    title: 'orderList',
    route: 'admin-order',
    resource: 'admin-order',
    icon: 'order.svg',
  },
  {
    // title: 'สร้างใบชำระให้ลูกค้า',
    title: 'createInvoice',
    route: 'admin-payment-user',
    resource: 'admin-payment',
    icon: 'payment-user.svg',
  },
  {
    // title: 'ส่งของไปจีน',
    title: 'sendToChina',
    route: 'admin-paymenttochina',
    resource: 'admin-paymenttochina',
    icon: 'paymenttochina.svg',
  },
  {
    // title: 'ใบเสร็จ',
    title: 'receipt',
    route: 'admin-finance-receipt',
    resource: 'admin-receipt',
    icon: 'receipt.svg',
  },
  {
    // title: 'พิมพ์รายการสั่งซื้อ',
    title: 'printOrder',
    route: 'admin-order-print',
    resource: 'admin-order-print',
    icon: 'order-print.svg',
  },
  {
    // title: 'ชำระเงินค่าขนส่ง',
    title: 'payShippingFee',
    route: 'admin-payment',
    resource: 'admin-pay-importfee',
    icon: 'payment.svg',
  },
  {
    // title: 'รายการส่งออกสินค้า',
    title: 'exportList',
    route: 'admin-export',
    resource: 'admin-export',
    icon: 'order.svg',
  },
  {
    title: 'ยังไม่ได้ชำระเงิน',
    route: 'admin-nobilling',
    resource: 'admin-nobilling',
    icon: 'payment.svg',
  },
  {
    // title: 'ล็อตสินค้า',
    title: 'productLot',
    route: 'admin-warehouse-lot',
    resource: 'admin-lot',
    icon: 'order.svg',
  },
  {
    // title: 'รับสินค้าเข้าไทย',
    title: 'receiveInThailand',
    route: 'admin-warehouse-thai',
    resource: 'admin-warehouse-thai',
    icon: 'order.svg',
  },
  {
    // title: 'ระบบ QC',
    title: 'qcSystem',
    route: 'admin-warehouse-qc',
    resource: 'admin-warehouse-qc',
    icon: 'QC.svg',
  },
  {
    // title: 'ข้อมูลโกดัง',
    title: 'warehouseInfo',
    route: 'admin-warehouse-section',
    resource: 'admin-warehouse',
    icon: 'data.svg',
  },
  {
    // title: 'รหัสพัสดุ',
    title: 'parcelCode',
    route: 'admin-tracking',
    resource: 'admin-tracking',
    icon: 'barcode.svg',
  },
  {
    // title: 'รวมรหัสพัสดุ',
    title: 'รวมรหัสพัสดุ',
    route: 'admin-combine_track',
    resource: 'admin-combine_track',
    icon: 'barcode.svg',
  },
  {
    // title: 'รวมรหัสพัสดุ',
    title: 'รหัสพัสดุ noname',
    route: 'admin-postnumber-noname',
    resource: 'admin-postnumber-noname',
    icon: 'barcode.svg',
  },
  // {
  //   title: 'รวมรหัสพัสดุ',
  //   route: 'admin-combine-track',
  //   resource: 'admin-tracking',
  //   icon: 'barcode.svg',
  // },
  {
    // title: 'แจ้งสินค้าที่ไม่ได้รับ',
    title: 'reportMissingGoods',
    route: 'admin-lost-package',
    resource: 'admin-productfailed',
    icon: 'order-no.svg',
  },
  // {
  //   title: 'lot10',
  //   route: 'admin-warehouse-lot10',
  //   resource: 'admin-productfailed',
  // },
  {
    // title: 'แต้ม',
    title: 'points',
    route: 'admin-point',
    resource: 'admin-point',
    icon: 'point.svg',
  },
  {
    // title: 'แลกแต้ม',
    title: 'redeemPoints',
    route: 'admin-point-withdraw',
    resource: 'admin-point-withdraw',
    icon: 'point-withdraw.svg',
  },
  {
    // title: 'สินค้าไม่มีเจ้าของ',
    title: 'ownerlessGoods',
    route: 'admin-goodsconfirm',
    resource: 'admin-ownerlessproduct',
    icon: 'order-no.svg',
  },
  // {
  //   title: 'stockRemaining',
  //   route: 'admin-mall-stock',
  //   resource: 'admin-productfailed',
  //   icon: 'order.svg',
  // },
  // {
  //   title: 'เช็คสถานะและแจ้งส่งของ',
  //   route: 'admin-payment-create',
  //   resource: 'admin-productfailed',
  // },
  // {
  //   title: 'ownerlessGoods5',
  //   route: 'admin-goodsconfirm',
  //   resource: 'admin-track',
  //   icon: 'dashboard.svg',
  // },
  {
    // title: 'รายงาน',
    title: 'report',
    route: 'admin-report',
    resource: 'admin-report',
    icon: 'balance.svg',
  },
  {
    title: 'confirm',
    icon: 'user.svg',
    // tag: '2',
    tagVariant: 'light-warning',
    children: [
      {
        // title: 'ยืนยันข้อมูลส่วนบุคคล',
        title: 'key-255',
        route: 'admin-verifyInfomation',
        resource: 'admin-verifyInfomation',
        icon: 'role.svg',
        count: true,
      },
      {
        // title: 'ยืนยันข้อมูลบัญชี',
        title: 'key-256',
        route: 'admin-verifyAccount',
        resource: 'admin-verifyAccount',
        icon: 'balance.svg',
        count: true,
      },
    ],
  },
  {
    title: 'finance',
    icon: 'wallet.svg',
    // tag: '2',
    tagVariant: 'light-warning',
    children: [
      {
        // title: 'เติมเงินเข้าระบบ',
        title: 'topupSystem',
        route: 'admin-refill',
        resource: 'admin-refill',
        icon: 'refill.svg',
      },
      {
        // title: 'สเตทเม้น',
        title: 'stateMent',
        route: 'admin-statement',
        resource: 'admin-statement',
        icon: 'statement.png',
      },
      {
        // title: 'ถอนเงินเข้าบัญชีลูกค้า',
        title: 'withdrawToCustomerAccount',
        route: 'admin-finance-withdraw',
        resource: 'admin-withdraw',
        icon: 'finance-withdraw.svg',
      },
      {
        // title: 'โอนเงินไปจีน',
        title: 'transferToChina',
        route: 'admin-transfer',
        resource: 'admin-transfer',
        icon: 'transfer.svg',
      },
      {
        // title: 'เติมเงินอาลีเพย์',
        title: 'topupAlipay',
        route: 'admin-china-alipay',
        resource: 'admin-alipay',
        icon: 'china-alipay.svg',
      },
      {
        // title: 'ฝากจ่ายถาเปา',
        title: 'DepositTaobao',
        route: 'admin-china-payfor',
        resource: 'admin-payfor',
        icon: 'china-payfor.svg',
      },
      // {
      //   title: 'แลกหยวนเป็นบาท',
      //   route: 'admin-china-exchange',
      //   resource: 'admin-productfailed',
      //   icon: 'china-exchange.svg',
      // },
      {
        // title: 'สมุดบัญชี',
        title: 'ledger',
        route: 'admin-balance',
        resource: 'admin-balance',
        icon: 'balance.svg',
      },
      {
        // title: 'ค่าคอมมิสชั่น',
        title: 'key-318',
        route: 'admin-commission',
        resource: 'admin-commission',
        icon: 'user.svg',
      },
    ],
  },
  {
    title: 'settings',
    icon: 'settings.svg',
    // tag: '2',
    tagVariant: 'light-warning',
    children: [
      {
        // title: 'กลุ่มผู้ใช้งาน',
        title: 'userGroup',
        route: 'admin-user-type',
        resource: 'admin-settingusertype',
        icon: 'user.svg',
      },
      {
        // title: 'สิทธิ์การใช้งาน',
        title: 'accessRights',
        route: 'admin-role',
        resource: 'admin-settingrole',
        icon: 'role.svg',
      },
      {
        // title: 'ประเภทการสินค้า',
        title: 'productType',
        route: 'admin-product-type',
        resource: 'admin-product-type',
        icon: 'product-type.svg',
      },
      {
        // title: 'ประเภทการจัดส่ง',
        title: 'deliveryType',
        route: 'admin-shipping-type',
        resource: 'admin-shipping-type',
        icon: 'shipping-type.svg',
      },
      {
        // title: 'บัญชีธนาคาร',
        title: 'bankAccount',
        route: 'admin-setting-bank',
        resource: 'admin-bank',
        icon: 'setting-bank.svg',
      },
      {
        // title: 'โปรโมชั่น',
        title: 'promotion',
        route: 'admin-promotion',
        resource: 'admin-promotion',
        icon: 'promotion.svg',
      },
      {
        // title: 'รายการคูปอง',
        title: 'couponList',
        route: 'admin-coupon',
        resource: 'admin-coupon',
        icon: 'coupon.svg',
      },
      {
        // title: 'รายการคูปอง',
        title: 'เรทคูปองพิเศษ',
        route: 'admin-coupon-rate',
        resource: 'admin-coupon_rate',
        icon: 'coupon-rate.svg',
      },
      {
        // title: 'ทั่วไป',
        title: 'general',
        route: 'admin-general',
        resource: 'admin-general',
        icon: 'general.svg',
      },
      {
        // title: 'บัญชีฝากจ่าย',
        title: 'depositAccount',
        route: 'admin-alipay',
        resource: 'admin-alipay-Account',
        icon: 'alipay.svg',
      },
      {
        // title: 'ค่าบริการโกดัง',
        title: 'warehouseFee',
        route: 'admin-service-price',
        resource: 'admin-service-price',
        icon: 'service-price.svg',
      },
      {
        // title: 'ปลดล็อคถอนเงิน',
        title: 'unlockWithdrawal',
        route: 'admin-withdraw-unlockaq',
        resource: 'admin-withdraw-unlock',
        icon: 'withdraw-unlockaq.svg',
      },
      // {
      //   title: 'personalInfo',
      //   route: 'admin-profile',
      //   resource: 'admin-withdraw-unlock',
      //   icon: 'balance.svg',
      // },
    ],
  },
  {
    title: 'help',
    icon: 'circle.svg',
    tagVariant: 'light-warning',
    children: [
      // {
      //   title: 'ศูนย์แจ้งปัญหาสำหรับลูกค้า',
      //   route: 'question',
      //   resource: 'admin-question',
      // },
      // {
      //   title: 'ศูนย์แจ้งปัญหา',
      //   route: 'admin-report',
      //   resource: 'admin-productfailed',
      //   icon: 'report.svg',
      // },
      {
        // title: 'คำถามที่พบบ่อย',
        title: 'faq',
        route: 'admin-faq',
        resource: 'admin-faq',
        icon: 'faq.svg',
      },
      {
        // title: 'ประกาศ',
        title: 'announcement',
        route: 'admin-notice',
        resource: 'admin-notice',
        icon: 'megaphone.svg',
      },
    ],
  },
  {
    // title: 'ร้านค้า',
    title: 'ปิดงานโกดัง',
    route: 'admin-closework',
    icon: 'setting-bank.svg',
    resource: 'admin-closework',
  },
  {
    // title: 'ร้านค้า',
    title: 'shop',
    route: 'new-pages',
    icon: 'store.svg',
    resource: 'admin-order',
  },
]
